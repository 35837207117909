import type { Plugin } from '@nuxt/types'
import cartProductAddGtm from './gtm/cartProductAdd'
import cartProductAddGtag from './gtag/cartProductAdd'
import cartProductRemoveGtm from './gtm/cartProductRemove'
import cartProductRemoveGtag from './gtag/cartProductRemove'
import searchConfirmGtag from './gtag/searchConfirm'
import searchConfirmGtm from './gtm/searchConfirm'
import selectSearchResultGtag from './gtag/selectSearchResult'
import selectSearchResultGtm from './gtm/selectSearchResult'
import orderRefundGtm from './gtm/orderRefund'
import productViewGtm from './gtm/productView'
import productViewGtag from './gtag/productView'
import afterCheckoutGtm from './gtm/afterCheckout'
import afterCheckoutGtag from './gtag/afterCheckout'
import beginCheckoutGtm from './gtm/beginCheckout'
import beginCheckoutGtag from './gtag/beginCheckout'
import productSelectGtm from './gtm/productSelect'
import productListViewGtm from './gtm/productListView'
import submitFormDataGtm from './gtm/submitFormData'
import saleSelectGtm from './gtm/saleSelect'
import bannerSelectGtm from './gtm/bannerSelect'
import saleViewGtm from './gtm/saleView'
import addToCompareGtm from './gtm/addToCompare'
import addToCompareGtag from './gtag/addToCompare'
import addToFavoriteGtm from './gtm/addToFavorite'
import addToFavoriteGtag from './gtag/addToFavorite'
import viewCatalogPageGtm from './gtm/viewCatalogPage'
import viewCatalogPageGtag from './gtag/viewCatalogPage'
import addToCartAccessoryGtm from './gtm/addToCartAccessory'
import addToCartAccessoryGtag from './gtag/addToCartAccessory'
import chooseDeliveryTime from './gtm/chooseDeliveryTime'
import chooseDeliveryDate from './gtm/chooseDeliveryDate'
import authTypeGtm from './gtm/auth-type'
import cleanEcommerceGtm from './gtm/clearEcommerce'
import fastOrdering from './gtm/fastOrdering'
import searchQuantity from './gtm/searchQuantity'
import installmentStep from './gtm/installmentStep'
import poscreditInit from './gtm/poscreditInit'
import byInstallment from './gtm/byInstallment'
import selectDelivery from './gtm/selectDelivery'
import loanToPay from './gtm/loanToPay'
import loanDetail from './gtm/loanDetail'
import loanUser from './gtm/loanUser'
import availableExpress from './gtm/availableExpress'
import successExpress from './gtm/successExpress'
import type { CartProduct } from '~/type/product/Product'

/**
 * Плагин для отправки аналитики по электронной коммерции, сейчас сервисов в которые отправляем два: GTM и Criteo
 */
const plugin: Plugin = (ctx) => {
  if (process.env.NODE_ENV === 'production') {
    ctx.$gtm.init('UA-74048954-1')
    ctx.$gtm.init('GTM-TGNKSL')
  }

  function publishGtag(data: { name?: string; data: any }) {
    ctx.$gtm.push('event')
    ctx.$gtm.push(data?.name)
    ctx.$gtm.push(data?.data)
  }

  function publishGtm(data: any) {
    ctx.$gtm.push(data)
  }

  ctx.$emitter.on('cart-product-add', ({ product }) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(cartProductAddGtm(product))
    publishGtag(cartProductAddGtag(product))
  })

  ctx.$emitter.on('cart-product-remove', ({ product, cart }) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(cartProductRemoveGtm(product))
    publishGtag(cartProductRemoveGtag(product))
    if (cart)
      setCartToMindbox(cart)
  })

  ctx.$emitter.on('click-delivery-date', (data) => {
    publishGtm(chooseDeliveryDate(data))
  })

  ctx.$emitter.on('click-delivery-time', () => {
    publishGtm(chooseDeliveryTime())
  })

  ctx.$emitter.on('after-checkout', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(afterCheckoutGtm(data))
    publishGtag(afterCheckoutGtag(data))

    ctx.$metrika.reachGoal(ctx.$pinia.state.value.resize.isMobile ? 'vue_dev_user_placed_order' : 'vue_dev_user_placed_order_desktop')

    if (data.order?.contact?.phone) {
      ctx.$refiner({
        type: 'identifyUser',
        data: {
          phone: data.order?.contact?.phone,
        },
      })
    }

    if (data.userId) {
      ctx.$refiner({
        type: 'showForm',
        data: {
          id: '7fbd6e80-3b26-11ec-9380-051620e77484',
          force: true,
        },
      })
    }
    else {
      ctx.$refiner({
        type: 'showForm',
        data: {
          id: '10bcdc00-ac6d-11ed-9f54-fdf677221a9d',
          force: true,
        },
      })
    }
  })

  ctx.$emitter.on('begin-checkout', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(beginCheckoutGtm(data))
    publishGtag(beginCheckoutGtag(data))
  })

  ctx.$emitter.on('product-select', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(productSelectGtm(data))
  })

  ctx.$emitter.on('product-list-view', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(productListViewGtm(data))
  })

  ctx.$emitter.on('sale-select', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(saleSelectGtm(data))
  })

  ctx.$emitter.on('banner-select', (data) => {
    publishGtm(bannerSelectGtm(data))
  })

  ctx.$emitter.on('submit-form-data', (data) => {
    publishGtm(submitFormDataGtm(data))
  })

  ctx.$emitter.on('quantity-search', (data) => {
    publishGtm(searchQuantity(data))
  })

  ctx.$emitter.on('installment-step', (data) => {
    publishGtm(installmentStep(data.step))
  })

  ctx.$emitter.on('loan-poscredit-init', () => {
    publishGtm(poscreditInit())
  })

  ctx.$emitter.on('loan-by-installment', () => {
    publishGtm(byInstallment())
  })

  ctx.$emitter.on('loan-available-express', () => {
    publishGtm(availableExpress())
  })

  ctx.$emitter.on('loan-success-express', () => {
    publishGtm(successExpress())
  })

  ctx.$emitter.on('sale-view', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(saleViewGtm(data))
  })

  ctx.$emitter.on('delivery-select', (data) => {
    publishGtm(selectDelivery(data))
  })

  ctx.$emitter.on('add-to-compare', (data) => {
    publishGtm(addToCompareGtm(data))
    publishGtag(addToCompareGtag(data))

    ctx.$metrika.reachGoal('vue_dev_user_add_item_in_compare')
  })

  ctx.$emitter.on('add-to-favorite', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(addToFavoriteGtm(data))
    publishGtag(addToFavoriteGtag(data))

    ctx.$metrika.reachGoal('vue_dev_user_add_item_in_favorite')
  })

  ctx.$emitter.on('loan-to-pay', () => {
    publishGtm(loanToPay())
  })

  ctx.$emitter.on('loan-open-details', ({ paymentOverdue }) => {
    publishGtm(loanDetail(paymentOverdue))
  })

  ctx.$emitter.on('loan-open-user', ({ isAuth }) => {
    publishGtm(loanUser(isAuth))
  })

  ctx.$emitter.on('remove-from-favorite', (data) => {
    window.mindbox('sync', {
      operation: 'Website.RemoveFromWishList',
      data: {
        removeProductFromList: {
          product: {
            ids: {
              website: data,
            },
          },
        },
      },
    })
  })

  ctx.$emitter.on('add-to-cart-accessory', (data) => {
    publishGtm(addToCartAccessoryGtm(data))
    publishGtag(addToCartAccessoryGtag(data))
  })

  ctx.$emitter.on('get-favorite-list', ({ productListId }) => {
    window.mindbox('sync', {
      operation: 'Website.SetWishList',
      data: {
        productList: productListId.map((id) => {
          return {
            product: {
              ids: {
                website: id,
              },
            },
            count: 1,
          }
        }),
      },
    })
  })

  ctx.$emitter.on('get-cart-list', ({ productListId, productListItem }) => {
    window.mindbox('sync', {
      operation: 'Website.SetCart',
      data: {
        productList: productListId.map((id) => {
          return {
            product: {
              ids: {
                website: id,
              },
            },
            count: productListItem[id].quantity,
          }
        }),
      },
    })
  })

  ctx.$emitter.on('view-category', (data) => {
    if (data.id) {
      window.mindbox('sync', {
        operation: 'Website.ViewCategory',
        data: {
          viewProductCategory: {
            productCategory: {
              ids: {
                website: data.id,
              },
            },
          },
        },
      })

      window.PopMechanicPageData = {
        type: 'category',
        category: {
          id: data.id,
          name: data.name,
        },
      }
    }

    publishGtm(viewCatalogPageGtm(data))
    publishGtag(viewCatalogPageGtag(data))
  })

  ctx.$emitter.on('search-confirm', (data) => {
    publishGtag(searchConfirmGtag(data))
    publishGtm(searchConfirmGtm(data))
    if (data.searchSource === 'history')
      ctx.$metrika.reachGoal('search-history')
  })

  ctx.$emitter.on('select-search-result', (data) => {
    publishGtag(selectSearchResultGtag(data))
    publishGtm(selectSearchResultGtm(data))
    if (data.selectResultType === 'catalog')
      ctx.$metrika.reachGoal('search-category')
    else if (data.selectResultType === 'product')
      ctx.$metrika.reachGoal('search-product')
  })

  ctx.$emitter.on('order-reject-submitted', (data) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(orderRefundGtm(data))
  })

  ctx.$emitter.on('auth-passed', (data) => {
    data.username?.includes('@')
      ? publishGtm(submitFormDataGtm({ email: data.username }))
      : publishGtm(submitFormDataGtm({ phone: data.username || data.phone }))

    ctx.$metrika.reachGoal(data.activeTypeBlock === 'password' ? 'auth-password' : 'auth-sms-code')
    ctx.$metrika.reachGoal('vue_dev_user_logged')

    data.activeTypeBlock === 'password'
      ? publishGtm(authTypeGtm({ type: 'password' }))
      : publishGtm(authTypeGtm({ type: 'sms' }))
  })

  ctx.$emitter.on('product-view', ({ product, statusCode }) => {
    publishGtm(cleanEcommerceGtm())
    publishGtm(productViewGtm(product))
    publishGtag(productViewGtag(product))
    if (statusCode === 'NOT_AVAILABLE')
      ctx.$metrika.reachGoal('vue_dev_net_v_nalichii')

    window.mindbox('sync', {
      operation: 'Website.ViewProduct',
      data: {
        viewProduct: {
          product: {
            ids: {
              website: product.id,
            },
          },
        },
      },
    })
  })

  ctx.$emitter.on('clear-favorite-list', () => {
    window.mindbox('sync', {
      operation: 'Website.ClearWishList',
      data: {},
    })
  })

  ctx.$emitter.on('quick-order-submitted', ({ phone }) => {
    publishGtm(submitFormDataGtm({ phone }))
    ctx.$metrika.reachGoal('quick-order')
  })

  ctx.$emitter.on('apply-promocode', (data) => {
    if (data.status === 'success') {
      if (data.discount)
        ctx.$metrika.reachGoal('promocode_success')
      else ctx.$metrika.reachGoal('wrong_promocode')
    }
    else { ctx.$metrika.reachGoal('error_promocode') }
  })

  ctx.$emitter.on('share-bonus-modal-close', () => {
    ctx.$refiner({
      type: 'showForm',
      data: {
        id: '20b7e470-56ba-11ed-ac90-8b3603eede84',
        force: false,
      },
    })
  })

  ctx.$emitter.on('show-search-survey', () => {
    ctx.$refiner({
      type: 'showForm',
      data: {
        id: '2fd4a080-56d7-11ec-b94d-b5fd6f3cb627',
        force: false,
      },
    })
  })

  ctx.$emitter.on('fast-ordering', (data) => {
    publishGtm(fastOrdering(data))
  })

  ctx.$emitter.on('notification-order-review-created', () => ctx.$metrika.reachGoal('vue_dev_notification_order_review_created'))
  ctx.$emitter.on('click-next-checkout-step', ({ step }) => ctx.$metrika.reachGoal(`vue_dev_user_passed_${step}_step`))
  ctx.$emitter.on('loan-form-open', () => ctx.$metrika.reachGoal('loan-form-open'))
  ctx.$emitter.on('click-filter-arrow-property', () => ctx.$metrika.reachGoal('click_filter_arrow_property'))
  ctx.$emitter.on('mainpage/on-infoblock-auth-click', () => ctx.$metrika.reachGoal('main_infoblock_auth'))
  ctx.$emitter.on('set-user-city', () => ctx.$metrika.reachGoal('vue_dev_user_set_city'))
  ctx.$emitter.on('express-delivery', () => ctx.$metrika.reachGoal('click_button_express_delivery'))
  ctx.$emitter.on('show-order-review', () => ctx.$metrika.reachGoal('vue_dev_notification_order_review_show'))
  ctx.$emitter.on('click-order-review', () => ctx.$metrika.reachGoal('vue_dev_notification_order_review_click'))
  ctx.$emitter.on('on-empty-search', () => ctx.$metrika.reachGoal('vue_dev_empty_search'))
  ctx.$emitter.on('mainpage/on-category-click', () => ctx.$metrika.reachGoal('mainpage.click_on_category'))
  ctx.$emitter.on('mainpage/on-sale-banner-click', () => ctx.$metrika.reachGoal('mainpage.click_on_offer'))
  ctx.$emitter.on('mainpage/on-popular-category-click', ({ event }) => ctx.$metrika.reachGoal(event))

  ctx.$emitter.on('ab-test-viewed', (event) => {
    publishGtag({
      name: 'growthbook-ab-test-viewed',
      data: event,
    })

    publishGtm({
      event: 'growthbook-ab-test-viewed',
      data: event,
    })

    ctx.$metrika.reachGoal(`growthbook-ab-test-viewed/${event.experimentId}/${event.variationId}`)
  })

  ctx.$emitter.on('mainpage/on-auth-banner-click', (event) => {
    publishGtag({
      name: 'main_click_auth_banner',
      data: event,
    })

    publishGtm({
      event: 'main_click_auth_banner',
      data: event,
    })

    ctx.$metrika.reachGoal(`main_click_auth_banner/${event.experimentId}/${event.variationId}`)
  })

  ctx.$emitter.on('page-open', (event) => {
    publishGtag({
      name: 'participate_test',
      data: event,
    })

    publishGtm({
      event: 'participate_test',
      data: event,
    })

    ctx.$metrika.reachGoal(`participate_test/${event.level_name}`)
  })

  ctx.$emitter.on('change-loyalty-status', (event) => {
    const eventName = event.name === 'exit' ? 'profile_click_button_refuse_participate' : 'profile_click_button_participate'

    publishGtag({
      name: eventName,
      data: event,
    })

    publishGtm({
      event: eventName,
      data: event,
    })

    ctx.$metrika.reachGoal(eventName)
  })
}

function setCartToMindbox(cart: { [key: number]: CartProduct }) {
  window.mindbox('sync', {
    operation: 'Website.SetCart',
    data: {
      productList: Object.keys(cart).map((id) => {
        return {
          product: {
            ids: {
              website: id,
            },
          },
          count: cart[Number(id)].quantity,
        }
      }),
    },
  })
}

export default plugin
